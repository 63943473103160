import HomeScreen from '@/components/HomeScreen/HomeScreen'

export default function Home() {
  return <HomeScreen />
}

export async function getStaticProps() {
  return {
    props: {},
  }
}
