import classnames from 'classnames'
import styles from './HomeScreen.module.scss'
import CenterContent from '../CenterContent/CenterContent'
import BottomContent from '../BottomContent/BottomContent'
import Button from '../Button/Button'
import useStore from '@/store'
import { TERMS_OF_USE_LINK } from '@/data'
import DesktopMessagingStars from '../DesktopMessaging/DesktopMessagingStars/DesktopMessagingStars'

const HomeScreen = ({ className }) => {
  const setModalKey = useStore(state => state.setModalKey)
  const setModalIsOpen = useStore(state => state.setModalIsOpen)

  return (
    <div className={classnames(styles.HomeScreen, className)}>
      <DesktopMessagingStars />
      <CenterContent>
        <h1 className={styles.title}>
          An extra
          <br />
          special gift
        </h1>
        <p className={styles.description}>
          A unique experience to attach a personalized video to your Lindt Chocolate box.
        </p>
      </CenterContent>
      <BottomContent>
        <Button
          onClick={() => {
            setModalKey('STORES')
            setModalIsOpen(true)
          }}
          label="View participating stores"
          primary
        />
        <p className={styles.termsOfUseLinkContainer}>
          <a
            className={styles.termsOfUseLink}
            href={TERMS_OF_USE_LINK}
            target="_blank"
          >
            General terms of use
          </a>
        </p>
      </BottomContent>
    </div>
  )
}

HomeScreen.displayName = 'HomeScreen'

export default HomeScreen
